import React, { ChangeEvent, useState } from "react";
import ModalComponent from "./ModalComponent";
import { BootstrapInput } from "../InputField";
import { loadingIcon } from "../../Assets/Images";

interface SearchStudentModalComponentProp {
  stateOpenSearchStudent: boolean;
  stateStudentSearchInput: { rollNumber: string };
  stateListErrors: string;
  stateLoadingPressed: boolean;

  cbHandleCloseSearchStudent: () => void;
  cbHandleInputRollNumber: (e: ChangeEvent<HTMLInputElement>) => void;
  cbHandleSearchStudent: (e: any) => void;
}

const SearchStudentModalComponent: React.FC<SearchStudentModalComponentProp> = (
  props: SearchStudentModalComponentProp
) => {
  console.log("In component SearchStudentModelComponent");
  console.log(
    "SearchStudentModalComponent: , roll number length = ",
    props.stateStudentSearchInput.rollNumber.length
  );

  const [loading, setLoading] = useState(false);

  return (
    <ModalComponent
      open={props.stateOpenSearchStudent}
      onClose={props.cbHandleCloseSearchStudent}
      title="Search for student with Roll number"
    >
      <>
        <div
          style={{
            background: "white",
            borderRadius: 20,
            height: "fit-content",

            border: "none #000",
          }}
        >
          <div className="modal-inputs">
            <BootstrapInput
              onChange={props.cbHandleInputRollNumber}
              name="rollNumber"
              style={{
                maxWidth: 250,
                width: window.innerWidth * 0.7,
                background: "white",
              }}
              type="roll"
              value={props.stateStudentSearchInput.rollNumber}
              placeholder="Roll Number"
            />
            {(!props.stateStudentSearchInput.rollNumber.length ||
              props.stateListErrors.length != 0) && (
              <div
                style={{
                  color: "red",
                  display: "auto",
                  paddingTop: "10px",
                }}
              >
                Cannot find student info
              </div>
            )}
            <button
              disabled={
                !props.stateStudentSearchInput.rollNumber.length || loading
              }
              className="modal-submit-button"
              onClick={(e) => {
                setLoading(true);
                props.cbHandleSearchStudent(e);
                setLoading(false);
              }}
              style={{
                alignSelf: "center",
                padding: "4%",
                fontSize: 16,
                whiteSpace: "nowrap",
              }}
            >
              {loading && <img src={loadingIcon} alt="Loading" />}
              {!loading && <a>Search for student</a>}
            </button>
          </div>
        </div>
      </>
    </ModalComponent>
  );
};

export default SearchStudentModalComponent;
