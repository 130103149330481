import React, { useEffect, useState } from "react";
import ModalComponent from "./ModalComponent";
import api from "../../Utils/axios/axiosMW";

interface UpdateExamLinkModalComponentProps {
  stateOpenUpdateExamLink: boolean;
  schoolID: string;
  batchesAr: string[];

  cbHandleCloseUpdateExamLink: () => void;
  cbFetchSchoolsList: () => void;
}

const UpdateExamLinkModalComponent = (
  props: UpdateExamLinkModalComponentProps
) => {
  console.log("In Update Exam Link Modal");
  const [loading, setLoading] = useState(false);
  const [linkPerBatch, setLinkPerBatch] = useState<{ [key: string]: string }>(
    {}
  );

  const [urlErrors, setUrlErrors] = useState<{ [key: string]: string }>({});
  const [isUrlErrorPresent, setIsUrlErrorPresent] = useState(true);
  const [examPolicyID, setExamPolicyID] = useState<1 | 2 | null>(null);


  const [apiErrors, setApiErrors] = useState<string>("");

  const handleSubmitUpdateExamLink = (e: any) => {
    console.log("handling submit");
    try {
      api
        .post(
          "update-exam-link",
          {
            examLink: linkPerBatch,
            schoolID: parseInt(props.schoolID),

            examPolicyID: examPolicyID,

          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log("Response on update-exam-link = ", response);
          if (response && response.data && response.data.status === "Success") {
            console.log("Success");
            props.cbHandleCloseUpdateExamLink();
            props.cbFetchSchoolsList();
          } else {
            console.log("Error in API call for updating exam link");
            setApiErrors("Something went wrong");
          }
        });
    } catch (err) {
      console.log(
        `Error in api call for updating exam link with school Id = ${props.schoolID}, err = ${err}`
      );
      setApiErrors("Something went wrong");
    }
  };

  const initializeAllLinks = () => {
    let tempObj: { [key: string]: string } = {};
    let tempErrors: { [key: string]: string } = {};
    for (let i = 0; i < props.batchesAr.length; i++) {
      const presBatch = props.batchesAr[i];
      tempObj[presBatch] = "";
      tempErrors[presBatch] = "Incorrect/Empty Url Format";
    }
    setLinkPerBatch(tempObj);
    setUrlErrors(tempErrors);
  };

  useEffect(() => {
    initializeAllLinks();
  }, [props.batchesAr]);

  useEffect(() => {
    let errorFound = false;
    if (Object.values(urlErrors).some((value) => value.length > 0)) {
      errorFound = true;
    }
    setIsUrlErrorPresent(errorFound);
  }, [urlErrors]);

  const handleInputExamUrl = (e: any, key: string) => {
    const enteredExamUrl = e.target.value;
    console.log("Entered url = ", enteredExamUrl);
    setLinkPerBatch((prevState) => ({
      ...prevState,
      [key]: enteredExamUrl,
    }));

    //check for url errors
    const urlRegex =
      /\b(?:https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|]/gi;

    let presUrlError = "";
    if (!urlRegex.test(enteredExamUrl)) {
      presUrlError = "Incorrect/Empty Url Format";
    }
    setUrlErrors((prevErrors) => ({
      ...prevErrors,
      [key]: presUrlError,
    }));
  };

  return (
    <ModalComponent
      open={props.stateOpenUpdateExamLink}
      onClose={props.cbHandleCloseUpdateExamLink}
      title="Input the Exam Links for the corresponding batches: "
    >
      <div
        style={{
          background: "white",
          borderRadius: 20,
          height: "fit-content",
          border: "none #000",
        }}
      >
        <br />
        <div className="modal-inputs">
          {Object.entries(linkPerBatch).map(([key, value]) => (
            <div key={key}>
              <div className="input-texts">
                Enter Exam Url for batch :
                <a
                  style={{
                    color: "rgb(51, 153, 102)",
                  }}
                >
                  {key}
                </a>
              </div>
              <input
                name="Exam url"
                className="input"
                onChange={(e) => {
                  handleInputExamUrl(e, key);
                }}
                style={{
                  paddingLeft: "20px",
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "#2C6AB1",
                  width: "220px",
                }}
                type="text"
                placeholder="Exam Link"
              />
              <div className="error-texts">{urlErrors[key]}</div>
              <br />
            </div>
          ))}
          <div className="error-texts">{apiErrors}</div>


          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ color: "rgb(44, 106, 177)", marginTop: 10 }}>
              Select Policy :{" "}
            </div>
            <div
              style={{
                display: "flex",
                width: 300,

                alignItems: "center",
                justifyContent: "space-around",
                marginTop: 10,
              }}
            >
              <div
                onClick={() => {
                  setExamPolicyID(1);
                }}
                style={{
                  padding: 5,
                  backgroundColor:
                    examPolicyID === 1
                      ? "rgb(44, 106, 177)"
                      : "rgba(0,0,0,0.9)",
                  borderRadius: 5,
                  color: "#fFF",
                  cursor: "pointer",
                }}
              >
                Policy 1.
              </div>
              <div
                onClick={() => {
                  setExamPolicyID(2);
                }}
                style={{
                  padding: 5,
                  backgroundColor:
                    examPolicyID === 2
                      ? "rgb(44, 106, 177)"
                      : "rgba(0,0,0,0.9)",
                  borderRadius: 5,
                  color: "#fFF",
                  cursor: "pointer",
                }}
              >
                Policy 2.
              </div>
            </div>
          </div>
          <button
            className="modal-submit-button"
            disabled={
              isUrlErrorPresent || loading === true || examPolicyID === null
            }

            onClick={(e) => {
              setLoading(true);
              handleSubmitUpdateExamLink(e);
              setLoading(false);
            }}
          >
            {" "}
            Submit Links{" "}
          </button>
          <button
            className="modal-submit-button"
            disabled={true === loading}
            onClick={(e) => {
              setLoading(true);
              props.cbHandleCloseUpdateExamLink();
              setLoading(false);
            }}
          >
            {" "}
            Cancel and close{" "}
          </button>
        </div>
      </div>
    </ModalComponent>
  );
};

export default UpdateExamLinkModalComponent;
