import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import bgup from "../Assets/Images/bgup.svg";
import logo from "../Assets/Images/Logo2.svg";
import icon from "../Assets/Images/icons8-edit.svg";
import downloadIcon from "../Assets/Images/downloadIcon.svg";
import "../css/List.css";
import "../Utils/DefinitionsInterfaces";
import { TableView } from "../Component/Tableview";
import ModalComponent from "../Component/ModalComponents/ModalComponent";
import { AxiosResponse } from "axios";
import { AdminContext } from "../Contexts/AdminContext";
import { BootstrapInput } from "../Component/InputField";
import { error } from "console";
//@ts-ignore
import ReactFileReader from "react-file-reader";
//@ts-ignore
import Papa from "papaparse";
import CsvParser from "../Component/ParsingFile/CsvParser";
import { format } from "date-fns";
import SearchStudentModalComponent from "../Component/ModalComponents/SearchStudentModalComponent";
import AddSchoolModalComponent from "../Component/ModalComponents/AddSchoolModalComponent";
import AddStudentsModalComponent from "../Component/ModalComponents/AddStudentsModalComponent";
import EditExamDateModalComponent from "../Component/ModalComponents/EditExamDateModalComponent";
import PageFooter from "../Component/PageFooter";
import FilterComponent from "../Component/FilterComponent";
import { CircularProgress, Tooltip } from "@mui/material";
import api from "../Utils/axios/axiosMW";
import { getAllStudent, getAllTeachers } from "../Utils/api/auth";
import SendInviteModalComponent from "../Component/ModalComponents/SendInviteModalComponent";
import NotifyWarningModalComponent from "../Component/ModalComponents/NotifyWarningModalComponent";
import UpdateExamLinkModalComponent from "../Component/ModalComponents/UpdateExamLinkModalComponent";
import { addTeacher, downloadTeacher, teacherIcon } from "../Assets/Images";
import AddTeacherModal from "../Component/ModalComponents/AddTeacherModal";
import UploadResultsModalComponent from "../Component/ModalComponents/UploadResultsModalComponent";
import CsvParserResults from "../Component/ParsingFile/CsvParserResults";


interface EditIconState {
  isOpen: boolean;
  selectedId: number | null;
}

function List() {
  const [openAddStudents, setOpenAddStudents] = useState<{
    isOpen: boolean;
    selectedId: number | null;
  }>({ isOpen: false, selectedId: null });
  const [openAddSchool, setOpenAddSchool] = useState(false);
  const [openSearchStudent, setSearchStudent] = useState(false);

  const [openSendInvite, setOpenSendInvite] = useState(false);
  const [schoolIdForInvite, setSchoolIdForInvite] = useState("0");
  const [inviteWarningText, setInviteWarningText] = useState("");

  const [openAddTeacher, setOpenAddTeacher] = useState(false);
  const [schoolIdForTeacher, setSchoolIdForTeacher] = useState("0");

  const [openNotifyModal, setOpenNotifyModal] = useState(false);
  const [schoolIdForNotify, setSchoolIdForNotify] = useState("0");
  const [notifyWarningText, setNotifyWarningText] = useState("");

  const [openExamLinkModal, setOpenExamLinkModal] = useState(false);
  const [schoolIdForUpdateLink, setSchoolIdForUpdateLink] = useState("0");
  const [batchesKeyAr, setBatchesKeyAr] = useState<string[]>([]);

  const [openEditIcon, setEditIcon] = useState<EditIconState>({
    isOpen: false,
    selectedId: null,
  });

  const [prevExamDateTime, setPrevExamDateTime] = useState("");

  const [schoolNameInput, setSchoolNameInput] = useState("");
  const [schoolCityInput, setSchoolCityInput] = useState("");
  const [schoolStateInput, setSchoolStateInput] = useState("");
  const [schoolPhoneInput, setSchoolPhoneInput] = useState("");
  const [schoolEmailIdInput, setSchoolEmailIdInput] = useState("");
  const [schoolPhoneNumErrorMessage, setSchoolPhoneNumErrorMessage] =
    useState("");
  const [schoolEmailIdErrorMessage, setSchoolEmailIdErrorMessage] =
    useState("");
  const [studentSearchInput, setStudentSearchInput] = useState({
    rollNumber: "",
  });

  const [uploadResults, setUploadResults] = useState<{
    isOpen: boolean;
    selectedId: null | number;
  }>({
    isOpen: false,
    selectedId: null,
  });


  const [examDateInput, setExamDateInput] = useState("");
  const [examTimeInputDuringAddSchool, setExamTimeInputDuringAddSchool] =
    useState("00:00:00"); //by default it set to 00:00:00 (hh:mm:ss)
  const [examTimeInput, setExamTimeInput] = useState("");
  const [studentsAddFilename, setStudentsAddFilename] = useState("");
  const [resultsAddFilename, setResultsAddFilename] = useState("");
  const [addStudentsObject, setAddStudentsObject] =
    useState<ApiRequestAddStudents>({ students: [] });
  const [addResultsObject, setAddResultsObject] =
    useState<ApiRequestUploadResults>({ studentReport: [], schoolID: "" });
  const [csvParseErrorMessage, setCsvParseErrorMessage] = useState("");
  const [parsingInProgress, setParsingInProgress] = useState(false);
  const [isPlaceholderVisible, setIsPlaceholderVisible] =
    useState<boolean>(true);
  const [schoolIdForAddStudents, setSchoolIdForAddStudents] = useState(0);
  const [listErrors, setlistErrors] = useState("");
  const [loadingPressed, setLoadingPressed] = useState(false);
  const [schoolListLoading, setSchoolListLoading] = useState(true);
  const [schoolIdForUploadResults, setSchoolIdForUploadResults] = useState(0);

  const {
    username,
    setUsername,
    currDate,
    currFormattedDate,
    setShowStudent,
    setStudentDetails,
    authToken,
    setAuthToken,
    authRefreshToken,
    setAuthRefreshToken,
    setAppLoading,
    appLoading,
  } = useContext(AdminContext);

  const [schoolListRows, setSchoolListRows] = useState<Row[]>([
    {
      id: "0",
      schoolName: "NONE",
      schoolCode: "NONE",
      students: "0",
      examDate: "",
      examStatus: "N/A",
      examLinkGenerated: false,
      isSchoolNotified: false,
      results: "N/A",
    },
  ]);
  const [numSchoolsResultIssued, setNumSchoolsResultIssued] = useState(0);

  const [filterText, setFilterText] = useState("");
  const filteredRows = schoolListRows.filter((row) =>
    row.schoolName.toLowerCase().includes(filterText.toLowerCase())
  );

  const handleFilterChange = (value: string) => {
    setFilterText(value);
  };

  useEffect(() => {
    console.log("List Page , loading");
    if ("" === username || "" === authToken || "" === authRefreshToken) {
      const storedAdminUserId = localStorage.getItem("nfoAdminUserId");
      const storedAdminMobNum = localStorage.getItem("nfoAdminMobNum");
      const storedAdminName = localStorage.getItem("nfoAdminName");
      setUsername(storedAdminName);
      const storedAdminAccessToken = localStorage.getItem(
        "nfoAdminAccessToken"
      );
      setAuthToken(storedAdminAccessToken);
      const storedAdminRefreshToken = localStorage.getItem(
        "nfoAdminRefreshToken"
      );
      setAuthRefreshToken(storedAdminRefreshToken);
    } else {
      fetchSchoolList();
    }
  }, []);

  useEffect(() => {
    fetchSchoolList();
  }, [username]);

  const handleCloseAddStudent = () => {
    setStudentsAddFilename("");
    setAddStudentsObject({
      students: [],
    });
    setSchoolIdForAddStudents(schoolListRows.length + 1);
    setOpenAddStudents({ isOpen: false, selectedId: null });
    setParsingInProgress(false);
    if (csvParseErrorMessage !== "") {
      setCsvParseErrorMessage("");
    }
  };
  const handleCloseUploadResults = () => {
    setResultsAddFilename("");
    setAddResultsObject({
      studentReport: [],
      schoolID: "",
    });
    setSchoolIdForUploadResults(0);
    setUploadResults({ isOpen: false, selectedId: null });
    setParsingInProgress(false);
    if (csvParseErrorMessage !== "") {
      setCsvParseErrorMessage("");
    }
  };

  const handleOpenUploadResults = (selectedId: number) => {
    console.log("selectedId = ", selectedId);
    setResultsAddFilename("Choose File");
    setAddResultsObject({
      studentReport: [],
      schoolID: selectedId,
    });
    setSchoolIdForUploadResults(selectedId);
    setCsvParseErrorMessage("");
    setParsingInProgress(false);
    setUploadResults({ isOpen: true, selectedId });
    setSchoolIdForAddStudents(selectedId);
  };

  const handleOpenAddStudents = (selectedId: number) => {
    console.log("selectedId = ", selectedId);
    setStudentsAddFilename("Choose File");
    setAddStudentsObject({
      students: [],
    });
    setCsvParseErrorMessage("");
    setParsingInProgress(false);
    setOpenAddStudents({ isOpen: true, selectedId });
    setSchoolIdForAddStudents(selectedId);
  };

  const handleCloseAddSchool = () => {
    setExamDateInput("");
    setExamTimeInputDuringAddSchool("00:00:00");
    setStudentsAddFilename("");
    setIsPlaceholderVisible(true);
    setSchoolNameInput("");
    setSchoolCityInput("");
    setSchoolStateInput("");
    setSchoolPhoneInput("");
    setSchoolEmailIdInput("");
    setSchoolPhoneNumErrorMessage("");
    setSchoolEmailIdErrorMessage("");
    setOpenAddSchool(false);
    setAddStudentsObject({
      students: [],
    });
    setParsingInProgress(false);
    if (csvParseErrorMessage !== "") {
      setCsvParseErrorMessage("");
    }
  };

  const handleOpenAddSchool = () => {
    setExamDateInput("");
    setExamTimeInputDuringAddSchool("00:00:00");
    setStudentsAddFilename("Upload Student CSV");
    setOpenAddSchool(true);
    setAddStudentsObject({
      students: [],
    });
    setCsvParseErrorMessage("");
    setParsingInProgress(false);
  };

  const handleCloseSearchStudent = () => {
    setSearchStudent(false);
  };

  const handleOpenSearchStudent = () => {
    setSearchStudent(true);
  };

  const handleOpenAddTeacher = (schoolId: string) => {
    setSchoolIdForTeacher(schoolId);
    setOpenAddTeacher(true);
  };

  const handleCloseAddTeacher = () => {
    setSchoolIdForTeacher("0");
    setOpenAddTeacher(false);
  };

  const handleOpenSendInvite = (schoolId: string) => {
    setSchoolIdForInvite(schoolId);
    setInviteWarningText(
      "This will send E-mail to all the students of the school."
    );
    setOpenSendInvite(true);
  };

  const handleCloseSendInvite = () => {
    setSchoolIdForInvite("0");
    setOpenSendInvite(false);
  };

  const getApplicableBatchesAndSet = (schoolID: string) => {
    try {
      api
        .post(
          "get-applicable-batches",
          {
            schoolID: parseInt(schoolID),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log("Response on getting applicable batches = ", response);
          if (
            response &&
            response.data &&
            response.data.status === "Success" &&
            response.data.data
          ) {
            if (response.data.data.length > 0) {
              let tempAr: string[] = [];
              for (let i = 0; i < response.data.data.length; i++) {
                tempAr.push(response.data.data[i]);
              }
              setBatchesKeyAr(tempAr);
            } else {
              console.log("No Batch present in school.");
            }
          } else {
            console.log("Not proper response");
          }
        });
    } catch (err) {
      console.log(
        `Error in getting applicable batches for ${schoolID} . Error = ${err}`
      );

      alert(
        `Error in getting applicable batches for ${schoolID} . Error = ${err}`
      );

    }
  };

  const handleOpenUpdateExamLink = (schoolId: string) => {
    try {
      getApplicableBatchesAndSet(schoolId);
      setSchoolIdForUpdateLink(schoolId);
      setOpenExamLinkModal(true);
    } catch (err) {
      console.log("Error in getting batches, err = ", err);

      alert(`Error in getting batches, err = ${err}`);

    }
  };

  const handleCloseUpdateExamLinkModal = () => {
    setSchoolIdForUpdateLink("0");
    setBatchesKeyAr([]);
    setOpenExamLinkModal(false);
  };

  const handleOpenNotifyModal = (schoolId: string) => {
    setSchoolIdForNotify(schoolId);
    setNotifyWarningText(
      "This will send Exam Notification to all the students of the school."
    );
    setOpenNotifyModal(true);
  };

  const handleCloseNotifyModal = () => {
    setSchoolIdForNotify("0");
    setOpenNotifyModal(false);
  };

  const handleCloseEditIcon = () => {
    setExamDateInput("");
    setExamTimeInput("");
    setPrevExamDateTime("");
    setEditIcon({ isOpen: false, selectedId: null });
  };

  const handleOpenEditIcon = (selectedId: number) => {
    setExamDateInput("");
    setExamTimeInput("");
    setEditIcon({ isOpen: true, selectedId });
    console.log("The id of selected, to edit, is = ", selectedId);
  };

  const handleInputSchoolName = (e: ChangeEvent<HTMLInputElement>) => {
    const enteredSchoolName = e.target.value;
    setSchoolNameInput(enteredSchoolName);
    console.log("EnteredSchoolName = ", enteredSchoolName);
  };

  const handleInputSchoolCity = (selectedSchoolCity: string) => {
    setSchoolCityInput(selectedSchoolCity);
    console.log("SelectedSchoolCity = ", selectedSchoolCity);
  };

  const handleInputSchoolState = (selectedSchoolState: string) => {
    setSchoolStateInput(selectedSchoolState);
    console.log("SelectedSchoolState = ", selectedSchoolState);
  };

  const handleInputSchoolPhoneNum = (e: ChangeEvent<HTMLInputElement>) => {
    const enteredSchoolPhoneNum = e.target.value;
    setSchoolPhoneInput(enteredSchoolPhoneNum);
    console.log("EnteredSchoolPhoneNum = ", enteredSchoolPhoneNum);

    //check for phone number errors
    var phoneErrors = "";
    const regex = /^$|^\d{10}$/; //either empty or exactly 10 digits

    if (!regex.test(enteredSchoolPhoneNum)) {
      phoneErrors = "Incorrect phone number format";
    }
    setSchoolPhoneNumErrorMessage(phoneErrors);
  };

  const handleInputSchoolEmailId = (e: ChangeEvent<HTMLInputElement>) => {
    const enteredSchoolEmailId = e.target.value;
    setSchoolEmailIdInput(enteredSchoolEmailId);
    console.log("EnteredSchoolEmailIdInput = ", enteredSchoolEmailId);

    //check for email id errors
    var emailErrors = "";
    const regex = /^$|^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; //either empty or proper email id format

    if (!regex.test(enteredSchoolEmailId)) {
      emailErrors = "Incorrect Email Id format";
    }
    setSchoolEmailIdErrorMessage(emailErrors);
  };

  const handleInputRollNumber = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setStudentSearchInput({ ...studentSearchInput, [name]: value });
    if (listErrors.length) setlistErrors("");
  };

  const handleInputExamDate = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedDate = e.target.value;
    setExamDateInput(selectedDate);
    console.log("Handling input exam date = ", selectedDate);
  };

  const handleInputExamTime = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedTime = e.target.value;
    setExamTimeInput(selectedTime);
    console.log("Handling Input Exam Time = ", selectedTime);
  };

  const handleInputExamTimeDuringAddSchool = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const selectedTime = e.target.value + ":00";
    setExamTimeInputDuringAddSchool(selectedTime);
    console.log("Handling Input Exam Time During Add School = ", selectedTime);
  };

  //Passing this function as callback to CsvParser
  const handleSettingStudentObject = (
    studentsDetails: ApiRequestAddStudents,
    errorMessage: string
  ) => {
    setAddStudentsObject(studentsDetails);
    setParsingInProgress(false);
    if (errorMessage !== "") {
      console.log("Error message from csv parser = ", errorMessage);
      setCsvParseErrorMessage(errorMessage);
    }
    console.log("Students in cb fn = ", studentsDetails);
  };

  //Passing this function as callback to CsvParser
  const handleSettingResultsObject = (
    resultsDetails: ApiRequestUploadResults,
    errorMessage: string
  ) => {
    setAddResultsObject(resultsDetails);
    setParsingInProgress(false);
    if (errorMessage !== "") {
      console.log("Error message from csv parser = ", errorMessage);
      setCsvParseErrorMessage(errorMessage);
    }
    console.log("Students in cb fn = ", resultsDetails);
  };

  const handleInputStudentsFile = (
    //@ts-ignore
    e
  ) => {
    console.log(e);
    console.log(e.fileList);
    console.log(e.fileList[0].name);
    setStudentsAddFilename(e.fileList[0].name);
    setAddStudentsObject({ students: [] });
    setCsvParseErrorMessage("");
    setParsingInProgress(true);

    // ADDING PAPA PARSE FOR PARSING
    console.log("openAddStudents.selectedId =", schoolIdForAddStudents);
    const studentsDetails = CsvParser(
      e.fileList[0],
      schoolIdForAddStudents,
      handleSettingStudentObject
    );
    console.log("Details back in List = ", studentsDetails);
  };
  const handleResultsFile = (
    //@ts-ignore
    e
  ) => {
    console.log(e);
    console.log(e.fileList);
    console.log(e.fileList[0].name);
    setResultsAddFilename(e.fileList[0].name);
    setAddResultsObject({ studentReport: [], schoolID: "" });
    setCsvParseErrorMessage("");
    setParsingInProgress(true);

    // ADDING PAPA PARSE FOR PARSING
    console.log("openAddStudents.selectedId =", schoolIdForUploadResults);
    const studentsDetails = CsvParserResults(
      e.fileList[0],
      schoolIdForUploadResults,
      handleSettingResultsObject
    );
    console.log("Details back in List = ", studentsDetails);
  };

  const handleInputStudentsDuringAddSchool = (
    //@ts-ignore
    e
  ) => {
    console.log("SchoolListRows.length = ", schoolListRows.length);
    console.log("schoolIdForAddStudents = ", schoolIdForAddStudents);
    handleInputStudentsFile(e);
  };

  //Returns the number of schools whose results are issued
  const populateSchools = (
    responseData: ApiResponseGetSchool,
    targetAr: Row[]
  ) => {
    var numResultsIssued = 0;
    console.log(
      "populating schools, numSchools = ",
      responseData.message.length
    );
    for (var i = 0; i < responseData.message.length; i++) {
      if (responseData.message[i].reportGenerated) numResultsIssued++;

      const examDateTimeString = responseData.message[i].examDate;
      const [examDate, examTime] = examDateTimeString.split(" ");
      console.log("Check ExamDate = ", examDate);
      console.log("Check ExamTime = ", examTime);

      const dateString = examDateTimeString;
      const examDateUnixTimestamp = Math.floor(
        new Date(dateString).getTime() / 1000
      );
      console.log("Exam date Unix Timestamp = ", examDateUnixTimestamp);

      const currentDate = new Date();
      const currentUnixTimestamp = Math.floor(currentDate.getTime() / 1000);
      console.log("Current date = ", currDate);
      console.log("Current Timestamp = ", currentUnixTimestamp);
      var examStatusString = "";
      if (examDateUnixTimestamp < currentUnixTimestamp) {
        examStatusString = "Exam Complete";
        if (responseData.message[i].studentNotified) {
          examStatusString = examStatusString + "/Notified";
        }
      } else {
        examStatusString = new Date(examDate).toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });
      }

      const school: Row = {
        id: responseData.message[i].schoolID,
        schoolName: responseData.message[i].schoolName,
        schoolCode: responseData.message[i].SchoolCode,
        students: responseData.message[i].totalNumberOfStudents.toString(),
        examDate: responseData.message[i].examDate,
        examStatus: examStatusString,
        examLinkGenerated: responseData.message[i].examLinkGenerated,
        isSchoolNotified: responseData.message[i].studentNotified,
        results: examStatusString.includes("Exam Complete")
          ? responseData.message[i].reportGenerated
            ? "Cert issued"
            : "Unissued"
          : "Exam pending",
      };
      targetAr.push(school);
    }
    return numResultsIssued;
  };

  const fetchSchoolList = async (): Promise<void> => {
    try {
      setSchoolListLoading(true);
      console.log("Requesting for schools");
      const response: AxiosResponse<ApiResponseGetSchool> = await api.get(
        "get-schools",
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.status === "Success") {
        const targetRows: Row[] = [];
        const numResultsIssued = populateSchools(response.data, targetRows);
        //dynamicRows= targetRows;
        setSchoolListRows(targetRows);
        setNumSchoolsResultIssued(numResultsIssued);
        console.log(
          "Previous schoolIdforAddStudents = ",
          schoolIdForAddStudents
        );
        console.log("Trying to add it to = ", response.data.message.length + 1);
        setSchoolIdForAddStudents(response.data.message.length + 1);
        setSchoolListLoading(false);
      }
    } catch (error) {
      console.log("Error Getting School:", error);
      alert(`Error Getting School:, ${error}`);
    }
  };

  const handleSearchStudent = async (
    //@ts-ignore
    e
  ) => {
    e.preventDefault();
    console.log("Will try getting student details");
    try {
      setLoadingPressed(true);
      const { rollNumber } = studentSearchInput;
      console.log("The roll number = ", rollNumber);
      const response: AxiosResponse<ApiResponseStudentDetails> = await api.post(
        "get-student-details",
        {
          studentUserName: rollNumber,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Response = ", response);
      if (
        response &&
        response.status === 200 &&
        response.data &&
        response.data.status_code === 200 &&
        response.data.data
      ) {
        setStudentDetails(response.data.data);
        setShowStudent(true);
        setLoadingPressed(false);
      } else {
        throw "Invalid response for student search";
      }
    } catch (error) {
      console.log("Error Sending rollNumber", error);
      alert(`Error Sending rollNumber, ${error}`);
      setlistErrors("Invalid Request or Wrong Roll Number");
      setLoadingPressed(false);
    }
  };

  const handleSubmitAddSchoolAndStudents = async (
    //@ts-ignore
    e
  ) => {
    console.log(
      "Handling Add School by submitting school details and student lists"
    );
    try {
      const response1 = await handleSubmitAddSchool(e);
      console.log("Response for adding ONLY School = ", response1.data);
      if (response1.data.status_code === 200) {
        console.log("SchoolName and ExamDate added successfully");
        if (
          addStudentsObject.students.length !== 0 &&
          studentsAddFilename !== "Upload Student CSV"
        ) {
          try {
            handleSubmitAddStudents(e, response1.data.data.schoolID);
          } catch (error) {

            alert(`Adding Students failed for this school with error:${error}`);

            console.log(
              "Adding Students failed for this school with error",
              error
            );
            console.log(
              "Please add the students from under the school added Now"
            );
          }
          //setOpenAddSchool(false);
          handleCloseAddSchool();
        } else {
          console.log("No CSV FILE uploaded, so no need to add students.");
          //setOpenAddSchool(false);
          handleCloseAddSchool();
          fetchSchoolList();
        }
      } else {
        throw new Error("Failure in Adding SChool :  Schoolname & ExamDate");
      }
    } catch (error) {
      alert(`Error in Request 1 , to add school , ${error}`);
      console.log("Error in Request 1 , to add school ", error);
      handleCloseAddSchool();
    }
  };

  const handleSubmitAddSchool = async (
    //@ts-ignore
    e
  ): Promise<AxiosResponse<ApiResponseAddStudents>> => {
    console.log("Handling submit of new schoolname, examdate, ");
    //@ts-ignore
    let response: AxiosResponse<ApiResponseAddStudents> = {
      data: {
        status: "Failed",
        status_code: 400,
        message: "Error while adding School",
        data: {
          schoolID: schoolIdForAddStudents,
        },
      },
      status: 400,
      statusText: "Failed",
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      // Defining the payload interface
      interface AddSchoolPayload {
        name: string;
        city: string;
        state: string;
        phone?: string;
        email?: string;
        examDate?: string;
      }

      // Constructing the payload
      const payload: AddSchoolPayload = {
        name: schoolNameInput,
        city: schoolCityInput,
        state: schoolStateInput,
      };

      // Adding phone, email, and exam date to the payload if they are not empty
      if (schoolPhoneInput) {
        payload.phone = schoolPhoneInput;
      }
      if (schoolEmailIdInput) {
        payload.email = schoolEmailIdInput;
      }
      if (examDateInput) {
        payload.examDate = examDateInput + " " + examTimeInputDuringAddSchool;
      }

      response = await api.post("add-school", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("Response for adding ONLY School = ", response);
      if (response.data.status_code === 200) {
        console.log("School added successfully (without student) ");
      } else {
        throw new Error("Failure in Adding SChool without students");
      }
    } catch (error) {
      console.log("Adding School, api1 Failed ", error);
      alert(`Adding School, api1 Failed , ${error}`);
    }
    return response;
  };

  const handleSubmitAddStudents = async (
    //@ts-ignore
    e,
    newSchoolID: number
  ) => {
    e.preventDefault();
    console.log("Handling submit of add students ");
    console.log("Add Students Object = ", addStudentsObject);

    try {
      let customAddStudentsObject: ApiRequestAddStudents = {
        students: [],
      };
      for (let i = 0; i < addStudentsObject.students.length; i++) {
        customAddStudentsObject.students.push(
          JSON.parse(JSON.stringify(addStudentsObject.students[i]))
        );
        customAddStudentsObject.students[i].schoolID = newSchoolID;
      }
      const response: AxiosResponse<ApiResponseAddStudents> = await api.post(
        "add-multiple-student",
        customAddStudentsObject,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Response = ", response);
      if (response.data.status_code === 200) {
        console.log("Students data uploaded correctly");
        //setEditIcon({isOpen: false, selectedId:null});
        handleCloseAddStudent();
        fetchSchoolList();
      } else {
        throw new Error("Failure in Adding students");
      }
    } catch (error) {
      console.log("Error Adding Students", error);
      alert(`Error Adding Students, ${error}`);
      handleCloseAddStudent();
    }
  };
  const handleSubmitUploadResults = async (
    //@ts-ignore
    e,
    schoolID: number
  ) => {
    e.preventDefault();
    console.log("Handling submit of add students ");
    console.log("Add Students Object = ", addResultsObject);

    try {
      setAppLoading(true);
      let customAddStudentsObject: ApiRequestUploadResults = {
        studentReport: [],
        schoolID: 0,
      };
      for (let i = 0; i < addResultsObject.studentReport.length; i++) {
        customAddStudentsObject.studentReport.push(
          JSON.parse(JSON.stringify(addResultsObject.studentReport[i]))
        );
        customAddStudentsObject.schoolID = +schoolID;
      }
      const response: AxiosResponse<ApiResponseUploadResults> = await api.post(
        "upload-student-report",
        customAddStudentsObject,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Response = ", response);
      if (response.data.status_code === 200) {
        setAppLoading(false);
        console.log("Students Report data uploaded correctly");
        //setEditIcon({isOpen: false, selectedId:null});
        handleCloseUploadResults();
        fetchSchoolList();
      } else {
        setAppLoading(false);
        throw new Error("Failure in Uploading Results");
      }
    } catch (error) {
      setAppLoading(false);
      console.log("Error Uploading Results", error);
      handleCloseUploadResults();
      alert(`Error Uploading Results,${error}`);
    }
  };

  const handleSubmitChangeDateTime = async (
    //@ts-ignore
    e
  ) => {
    e.preventDefault();
    console.log("Handling submit Change Exam Date Time");
    console.log("Exam Date = ", examDateInput);
    console.log("Exam Time = ", examTimeInput);
    if (examDateInput.length && examTimeInput.length) {
      try {
        const { selectedId } = openEditIcon;
        console.log("The school id = ", selectedId);
        const response: AxiosResponse<ApiResponseStudentDetails> =
          await api.post(
            "update-exam-date",
            {
              examDate: examDateInput + " " + examTimeInput + ":00", //Format requested by backend "yyyy-mm-dd hh:mm:ss"
              schoolID: selectedId,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
        console.log("Response = ", response);
        if (response.data.status_code === 200) {
          console.log("Exam date & time Updated successfully ");
          setEditIcon({ isOpen: false, selectedId: null });
          fetchSchoolList();
        } else {
          throw new Error("Failure in Exam Date Updation");
        }
      } catch (error) {
        console.log("Error Updating exam date", error);
        alert("Error Updating exam date");
      }
    }
  };

  const fetchStudentList = async (school_id: number) => {
    try {
      const response = await getAllStudent(school_id);
      console.log("Get all Students' response = ", response);
      //@ts-ignore
      if (
        response &&
        //@ts-ignore
        response.data.status_code === 200 &&
        //@ts-ignore
        response.data.data &&
        //@ts-ignore
        response.data.data.students
      ) {
        //@ts-ignore
        console.log("Response.data.students = ", response.data.data.students);
        //@ts-ignore
        const studentsList = response.data.data.students;
        const csvData = Papa.unparse(studentsList);
        const blob = new Blob([csvData], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Students-${school_id}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (err) {
      console.log("Error Fetching Students : ", err);
      alert(`Error Fetching Students : , ${err}`);
    }
  };
  const fetchTeacherList = async (school_id: number) => {
    try {
      const response = await getAllTeachers(school_id);
      console.log("Get all Students' response = ", response);
      //@ts-ignore
      if (
        response &&
        //@ts-ignore
        response.data.status_code === 200 &&
        //@ts-ignore
        response.data.data &&
        //@ts-ignore
        response.data.data.teachers
      ) {
        //@ts-ignore
        console.log("Response.data.students = ", response.data.data.teachers);
        //@ts-ignore
        const studentsList = response.data.data.teachers;
        const csvData = Papa.unparse(studentsList);
        const blob = new Blob([csvData], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Teachers-${school_id}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (err) {
      console.log("Error Fetching Students : ", err);
      alert(`"Error Fetching Students : ", ${err}`);
    }
  };

  const columns: Column[] = [
    {
      field: "schoolName",
      headerName: "School Name",
      flex: 1,
      type: "string",
      align: "center",
      headerClassName: "colHeaderStyles",
      renderCell: (params) => {
        return (
          <div
            style={{
              //fontWeight: "700",
              fontWeight: "bold",
              color: "#2C6AB1",
              fontSize: 20,
              fontFamily: "cardo",
              //lineHeight: "24.73px",
              textAlign: "center",
              whiteSpace: "normal",
            }}
          >
            {params.value.trim().substring(params.value.length - 2) === ","
              ? params.value.trim().substring(0, params.value.length - 2)
              : params.value.trim()}
          </div>
        );
      },
    },
    {
      field: "students",
      headerName: "Students",
      flex: 1,
      type: "string",
      align: "center",
      sortable: false,
      headerClassName: "colHeaderStyles",

      renderCell: (params) => {
        console.log(params.value);
        return (
          <div
            style={{
              color: params.row.results !== "Cert issued" ? "#2C6AB1" : "green",
              //color: "#2C6AB1",
              fontSize: 20,
              fontFamily: "Cardo",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="students-download-wrapper">
              <Tooltip title="Download Student CSV">
                <button
                  className="download-icon-button"
                  onClick={() => {
                    console.log("Row id = ", params.row.id);
                    fetchStudentList(params.row.id);
                  }}
                >
                  <img src={downloadIcon} alt="!" />
                </button>
              </Tooltip>
              {params.value} &nbsp;
              <Tooltip title="Download Teacher CSV">
                <button
                  className="download-icon-button"
                  onClick={() => {
                    console.log("Row id = ", params.row.id);
                    fetchTeacherList(params.row.id);
                  }}
                >
                  <img src={downloadTeacher} alt="!" />
                </button>
              </Tooltip>
              <Tooltip title="Add Teacher">
                <button
                  className="download-icon-button"
                  onClick={() => {
                    console.log("Clicked for Teacher creation");
                    handleOpenAddTeacher(params.row.id);
                  }}
                >
                  <img src={addTeacher} alt="T" />
                </button>
              </Tooltip>
            </div>
            <button
              className="invite-button"
              disabled={
                params.value === "0" ||
                params.row.examStatus === "Exam Complete" ||
                params.row.examStatus === "Exam Complete/Notified"
              }
              onClick={() => {
                console.log("Row id =", params.row.id);
                handleOpenSendInvite(params.row.id);
              }}
            >
              Send Invite
            </button>
            <button
              className="buttons"
              style={{
                color:
                  params.row.examStatus === "Exam Complete" ||
                  params.row.examStatus === "Exam Complete/Notified"
                    ? " #b7b0af "
                    : "#2C6AB1",
                cursor:
                  params.row.examStatus === "Exam Complete" ||
                  params.row.examStatus === "Exam Complete/Notified"
                    ? "auto"
                    : "pointer",
                border: "none",
                marginTop: 10,
              }}
              disabled={
                params.row.examStatus === "Exam Complete" ||
                params.row.examStatus === "Exam Complete/Notified"
              }
              onClick={() => {
                console.log("All params are : ", params);
                handleOpenAddStudents(params.row.id);
              }}
            >
              Add Students
            </button>
          </div>
        );
      },

    },
    {
      field: "examStatus",
      headerName: "Exam Status",
      flex: 1,
      type: "string",
      align: "center",
      headerClassName: "colHeaderStyles",
      renderCell: (params) => (
        <div
          style={{
            color:
              params.row.examStatus === "Exam Complete" ||
              params.row.examStatus === "Exam Complete/Notified"
                ? "green"
                : "#2C6AB1",
            //color: "#2C6AB1",
            fontSize: 20,
            fontFamily: "Cardo",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            {params.row.examStatus !== "Invalid Date" && (
              <div>{params.value}</div>
            )}
            {params.row.examStatus === "Invalid Date" && (
              <div>Date Unassigned</div>
            )}
            {!(
              params.row.examStatus === "Exam Complete" ||
              params.row.examStatus === "Exam Complete/Notified"
            ) && (
              <img
                src={icon}
                alt="Your Logo"
                className="logo-button"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (params.row.examStatus !== "Invalid Date") {
                    setPrevExamDateTime(params.row.examDate);
                  } else {
                    setPrevExamDateTime("");
                  }
                  handleOpenEditIcon(params.id);
                }}
              />
            )}
          </div>
          <button
            className="exam-status-buttons"
            disabled={
              params.row.students === "0" ||
              params.row.isSchoolNotified ||
              params.row.examStatus === "Exam Complete" ||
              params.row.examStatus === "Exam Complete/Notified" ||
              params.row.examStatus === "Invalid Date"
            }
            onClick={() => {
              console.log("Clicked update exam link");
              handleOpenUpdateExamLink(params.row.id);
            }}
          >
            Update Exam Link
          </button>
          <button
            className="exam-status-buttons"
            disabled={
              params.row.students === "0" ||
              !params.row.examLinkGenerated ||
              params.row.isSchoolNotified ||
              params.row.examStatus === "Exam Complete" ||
              params.row.examStatus === "Exam Complete/Notified" ||
              params.row.examStatus === "Invalid Date"
            }
            onClick={() => {
              console.log("All params are : ", params);
              handleOpenNotifyModal(params.row.id);
            }}
          >
            &nbsp; &nbsp; &nbsp; Notify &nbsp; &nbsp; &nbsp;
          </button>
        </div>
      ),
    },
    {
      field: "results",
      headerName: "Results",
      flex: 1,
      type: "string",
      align: "center",
      headerClassName: "colHeaderStyles",
      renderCell: (params) => (
        <div
          style={{
            color: params.row.results !== "Cert issued" ? "#2C6AB1" : "green",
            fontSize: 20,
            fontFamily: "Cardo",
            textAlign: "center",
          }}
        >
          {params.value}
          <br />
          {params.row.results !== "Unissued" && (
            <button
              className="button"
              disabled={
                params.row.results === "Cert issued" ||
                params.row.results === "Exam pending"
              }
              style={{
                cursor: "auto",
                color:
                  params.row.results !== "Cert issued" ? "auto" : "#b7b0af",
                backgroundColor: "#cbdff8",
              }}
            >
              Issue certificates
            </button>
          )}
          {params.row.results === "Unissued" && (
            <button
              onClick={() => {
                handleOpenUploadResults(params.row.id);
              }}
              className="button"
              style={{
                cursor: "pointer",
                color: "auto",
                backgroundColor: "auto",
              }}
            >
              Upload data
            </button>
          )}
        </div>
      ),
    },
  ];

  return (
    <div
      className="List"
      style={{
        backgroundColor: "#F3F3F3",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {schoolListLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100vw",
          }}
        >
          <CircularProgress />
        </div>
      )}
      <div className="row">
        <div>
          <div className="name" style={{ textAlign: "left" }}>
            Welcome {username}, <br />{" "}
            <div
              className="date"
              style={{ marginLeft: "0px", textAlign: "left" }}
            >
              {currFormattedDate}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "90%",
              alignItems: "left",
            }}
          >
            <button
              style={{
                fontFamily: "Cardo",
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "28px",
                letterSpacing: "0em",
                textAlign: "left",
                color: "#D75821",
                border: "1.14px solid #0000000D",
                background: "#f7d6c3",
                cursor: "pointer",
              }}
              onClick={() => {
                localStorage.removeItem("nfoAdminName");
                localStorage.removeItem("nfoAdminMobNum");
                localStorage.removeItem("nfoAdminUserId");
                localStorage.removeItem("nfoAdminAccessToken");
                localStorage.removeItem("nfoAdminRefreshToken");
                window.location.reload();
              }}
            >
              Sign Out
            </button>
          </div>
        </div>

        <img src={bgup} alt="" className="bg" />
        <img src={logo} className="log" alt="" />
      </div>

      <div className="cont">
        <div className="contain">
          <div className="head">
            {schoolListRows.length}{" "}
            {schoolListRows.length > 1 ? <>Schools</> : <>School</>} in list,{" "}
            <div className="school">
              {numSchoolsResultIssued}{" "}
              {numSchoolsResultIssued > 1 ? <>Schools</> : <>School</>} done
            </div>
          </div>
          <div
            style={{
              alignItems: "center",
              alignContent: "center",
              top: "20px",
              position: "relative",
            }}
          >
            <FilterComponent onFilterChange={handleFilterChange} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <button
              style={{
                cursor: "pointer",
                marginLeft: "10px",
              }}
              onClick={handleOpenAddSchool}
            >
              Add new School
            </button>
            <AddSchoolModalComponent
              stateOpenAddSchool={openAddSchool}
              stateIsPlaceholderVisible={isPlaceholderVisible}
              stateStudentsAddFilename={studentsAddFilename}
              stateSchoolNameInput={schoolNameInput}
              stateSchoolCityInput={schoolCityInput}
              stateSchoolStateInput={schoolStateInput}
              stateSchoolPhoneInput={schoolPhoneInput}
              stateSchoolEmailIdInput={schoolEmailIdInput}
              stateSchoolPhoneNumErrorMessage={schoolPhoneNumErrorMessage}
              stateSchoolEmailIdErrorMessage={schoolEmailIdErrorMessage}
              stateExamDateInput={examDateInput}
              stateExamTimeInput={examTimeInputDuringAddSchool}
              stateAddStudentsObject={addStudentsObject}
              stateCsvParseErrorMessage={csvParseErrorMessage}
              stateParsingInProgress={parsingInProgress}
              callbackHandleCloseAddSchool={handleCloseAddSchool}
              callbackHandleInputSchoolName={handleInputSchoolName}
              callbackHandleInputSchoolCity={handleInputSchoolCity}
              callbackHandleInputSchoolState={handleInputSchoolState}
              callbackHandleInputSchoolPhoneNum={handleInputSchoolPhoneNum}
              callbackHandleInputSchoolEmailId={handleInputSchoolEmailId}
              callbackSetIsPlaceholderVisible={setIsPlaceholderVisible}
              callbackHandleInputExamDate={handleInputExamDate}
              callbackHandleInputExamTime={handleInputExamTimeDuringAddSchool}
              callbackHandleInputStudentsDuringAddSchool={
                handleInputStudentsDuringAddSchool
              }
              callbackHandleSubmitAddSchoolAndStudents={
                handleSubmitAddSchoolAndStudents
              }
            />
            <button
              onClick={handleOpenSearchStudent}
              style={{
                backgroundColor: "rgb(235, 236, 237, 0.30)",
                color: "#2C6AB1",
                cursor: "pointer",
                marginLeft: "10px",
              }}
            >
              Search Student
            </button>
            <SearchStudentModalComponent
              stateOpenSearchStudent={openSearchStudent}
              stateStudentSearchInput={studentSearchInput}
              stateListErrors={listErrors}
              stateLoadingPressed={loadingPressed}
              cbHandleCloseSearchStudent={handleCloseSearchStudent}
              cbHandleInputRollNumber={handleInputRollNumber}
              cbHandleSearchStudent={handleSearchStudent}
            />
          </div>
        </div>

        <div className="table" style={{ width: "100%" }}>
          <TableView rows={filteredRows} columns={columns} />
        </div>
      </div>
      <AddStudentsModalComponent
        stateOpenAddStudents={openAddStudents}
        stateStudentsAddFilename={studentsAddFilename}
        stateAddStudentsObject={addStudentsObject}
        stateCsvParseErrorMessage={csvParseErrorMessage}
        stateParsingInProgress={parsingInProgress}
        statePresSchoolId={schoolIdForAddStudents}
        cbHandleCloseAddStudent={handleCloseAddStudent}
        cbHandleSubmitAddStudents={handleSubmitAddStudents}
        cbHandleInputStudentsFile={handleInputStudentsFile}
      />
      <EditExamDateModalComponent
        stateOpenEditIcon={openEditIcon}
        stateExamDateInput={examDateInput}
        stateExamTimeInput={examTimeInput}
        statePrevExamDateTimeStr={prevExamDateTime}
        cbHandleCloseEditIcon={handleCloseEditIcon}
        cbHandleInputExamDate={handleInputExamDate}
        cbHandleInputExamTime={handleInputExamTime}
        cbHandleSubmitChangeDateTime={handleSubmitChangeDateTime}
      />
      <AddTeacherModal
        currentTeacherCount={10}
        stateSchoolId={schoolIdForTeacher}
        stateOpenTeacherModal={openAddTeacher}
        cbHandleCloseTeacherModal={handleCloseAddTeacher}
      />
      <SendInviteModalComponent
        warningText={inviteWarningText}
        schoolID={schoolIdForInvite}
        stateOpenSendInvite={openSendInvite}
        cbHandleCloseSendInvite={handleCloseSendInvite}
      />
      <NotifyWarningModalComponent
        warningText={notifyWarningText}
        schoolID={schoolIdForNotify}
        stateOpenNotifyModal={openNotifyModal}
        cbHandleCloseNotifyModal={handleCloseNotifyModal}
        cbHandleFetchSchoolList={fetchSchoolList}
      />
      <UpdateExamLinkModalComponent
        stateOpenUpdateExamLink={openExamLinkModal}
        schoolID={schoolIdForUpdateLink}
        batchesAr={batchesKeyAr}
        cbHandleCloseUpdateExamLink={handleCloseUpdateExamLinkModal}
        cbFetchSchoolsList={fetchSchoolList}
      />

      <UploadResultsModalComponent
        stateOpenUploadResults={uploadResults}
        stateUploadResultsFilename={resultsAddFilename}
        stateUploadResultsObject={addResultsObject}
        stateCsvParseErrorMessage={csvParseErrorMessage}
        stateParsingInProgress={parsingInProgress}
        statePresSchoolId={schoolIdForUploadResults}
        cbHandleCloseUploadResults={handleCloseAddStudent}
        cbHandleSubmitResults={handleSubmitUploadResults}
        cbHandleResultsFile={handleResultsFile}
      />

      <PageFooter />
    </div>
  );
}

export default List;
